import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
   Moon, Sun, Globe, HomeIcon, Briefcase, User, Mail, Menu, X, GraduationCap
} from 'lucide-react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useTheme } from '../../contexts/ThemeContext';
import debounce from 'lodash/debounce';

// Memoized navigation items component
const NavigationItem = React.memo(({
   item,
   isActive,
   isDark,
   lang,
   onNavClick
}) => {
   return (
      <div className="relative z-10 flex-1 px-1">
         <a
            href={item.href}
            onClick={(e) => onNavClick(e, item.id)}
            className={`relative flex items-center justify-center gap-2.5 px-4 py-2.5 
               rounded-full transition-all duration-300 group overflow-hidden
               ${item.highlight ? 'animate-pulse-subtle' : ''}
               ${isActive
                  ? 'text-accent-color'
                  : isDark
                     ? 'text-text-light hover:text-accent-light'
                     : 'text-text-primary hover:text-accent-color'}`}
         >
            <div className={`flex items-center gap-2.5 transition-transform duration-300 
                  group-hover:scale-105 ${lang === 'ar' ? 'flex-row-reverse' : ''}`}>
               <item.icon className={`w-4 h-4 transition-all duration-300
                     ${isActive ? 'text-accent-color' : ''}
                     ${item.highlight ? 'animate-bounce-subtle' : ''}`} />
               <span className="font-medium flex items-center gap-2">
                  {item.label}
                  {item.highlight && (
                     <span className="px-1.5 py-0.5 text-xs font-bold bg-accent-color/20 
                        rounded-full text-accent-color whitespace-nowrap">New</span>
                  )}
               </span>
            </div>

            {!isActive && (
               <div className="absolute inset-0 rounded-full opacity-0 
                  group-hover:opacity-100 transition-opacity duration-300">
                  <div className={`absolute inset-0 
                     ${isDark ? 'bg-white/5' : 'bg-accent-color/5'}
                     ${item.highlight ? 'bg-accent-color/10' : ''}`} />
                  <div className="absolute inset-0 bg-gradient-to-r from-transparent 
                     via-accent-light/10 to-transparent animate-shine" />
               </div>
            )}
         </a>
      </div>
   );
});

// Memoized mobile navigation item component
const MobileNavigationItem = React.memo(({
   item,
   isActive,
   index,
   onNavClick
}) => {
   return (
      <a
         href={item.href}
         onClick={(e) => onNavClick(e, item.id)}
         className={`relative flex items-center gap-3 p-3 rounded-lg overflow-hidden
            transition-all duration-300 group
            ${isActive
               ? 'bg-accent-color/10 text-accent-color'
               : 'hover:bg-sidebar-hover text-text-light hover:text-accent-light'}`}
         style={{ transitionDelay: `${index * 50}ms` }}
      >
         <div className="relative flex items-center gap-3 transition-transform 
            duration-300 group-hover:scale-105">
            <item.icon className="w-5 h-5" />
            <span className="font-medium">{item.label}</span>
         </div>

         {isActive && (
            <>
               <div className="absolute inset-0 bg-accent-color/5" />
               <div className="absolute inset-0 bg-gradient-to-r from-transparent 
                           via-accent-light/10 to-transparent animate-shine" />
               <div className="absolute right-3 top-1/2 
                           transform -translate-y-1/2 w-1.5 h-1.5 rounded-full bg-accent-color">
                  <div className="absolute inset-0 rounded-full bg-accent-color/50 
                                 animate-ping-slow" />
               </div>
            </>
         )}
      </a>
   );
});

const Navigation = React.memo(() => {
   const [isMenuOpen, setIsMenuOpen] = useState(false);
   const [activeTab, setActiveTab] = useState('home');
   const [scrolled, setScrolled] = useState(false);
   const { t, toggleLanguage, lang } = useLanguage();
   const { isDark, toggleTheme } = useTheme();

   // Memoize navigation items
   const navigationItems = useMemo(() => [
      {
         id: 'home',
         href: '/#home',
         label: t('nav.home'),
         icon: HomeIcon,
      },
      {
         id: 'about',
         href: '/#about',
         label: t('nav.about'),
         icon: User,
      },
      {
         id: 'courses',
         href: '/#courses',
         label: t('nav.courses'),
         icon: GraduationCap,
      },
      {
         id: 'projects',
         href: '/#projects',
         label: t('nav.projects'),
         icon: Briefcase,
      },
      {
         id: 'contact',
         href: '/#contact',
         label: t('nav.contact'),
         icon: Mail,
      },
   ], [t]);

   // Memoize position calculation
   const getItemPosition = useCallback((id) => {
      const index = navigationItems.findIndex(item => item.id === id);
      const itemWidth = 100 / navigationItems.length;
      const position = index * itemWidth;
      return lang === 'ar' ? (100 - itemWidth - position) : position;
   }, [navigationItems, lang]);

   // Debounced scroll handler
   const handleBackgroundScroll = useMemo(
      () => debounce(() => setScrolled(window.scrollY > 50), 100),
      []
   );

   // Memoize section position calculation
   const getSectionPositions = useCallback(() => {
      const sections = ['home', 'about', 'courses', 'projects', 'contact'];
      return sections.map(id => {
         const element = document.getElementById(id);
         if (!element) return { id, top: 0, bottom: 0 };
         const rect = element.getBoundingClientRect();
         return {
            id,
            top: rect.top + window.pageYOffset,
            bottom: rect.bottom + window.pageYOffset
         };
      });
   }, []);

   // Debounced scroll handler for section updates
   const handleScroll = useMemo(
      () => debounce(() => {
         const scrollPosition = window.pageYOffset;
         const windowHeight = window.innerHeight;
         const sections = getSectionPositions();

         let activeSection = sections[0].id;
         let maxVisibility = 0;

         sections.forEach(section => {
            const visibleHeight = Math.min(section.bottom, scrollPosition + windowHeight) -
               Math.max(section.top, scrollPosition);

            if (visibleHeight > maxVisibility) {
               maxVisibility = visibleHeight;
               activeSection = section.id;
            }
         });

         if (activeSection !== activeTab) {
            setActiveTab(activeSection);
            window.history.replaceState(null, null, `#${activeSection}`);
         }
      }, 100),
      [activeTab, getSectionPositions]
   );

   // Memoized navigation click handler
   const handleNavClick = useCallback((e, id) => {
      e.preventDefault();
      setActiveTab(id);

      const navHeight = document.querySelector('nav').offsetHeight;
      const element = document.getElementById(id);

      if (element) {
         const elementPosition = element.getBoundingClientRect().top;
         const offsetPosition = elementPosition + window.pageYOffset - navHeight;

         window.scrollTo({
            top: id === 'home' ? 0 : offsetPosition,
            behavior: 'smooth'
         });

         setIsMenuOpen(false);
      }
   }, []);

   useEffect(() => {
      window.addEventListener('scroll', handleBackgroundScroll, { passive: true });
      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
         window.removeEventListener('scroll', handleBackgroundScroll);
         window.removeEventListener('scroll', handleScroll);
         handleBackgroundScroll.cancel();
         handleScroll.cancel();
      };
   }, [handleBackgroundScroll, handleScroll]);

   useEffect(() => {
      const handleHashChange = () => {
         const hash = window.location.hash.slice(1) || 'home';
         setActiveTab(hash);
      };

      handleHashChange();
      window.addEventListener('hashchange', handleHashChange);
      return () => window.removeEventListener('hashchange', handleHashChange);
   }, []);

   return (
      <nav
         className={`fixed w-full z-50 transition-colors duration-300
            ${scrolled
               ? `py-3 ${isDark
                  ? 'bg-sidebar-bg/95 backdrop-blur-md shadow-[0_4px_30px_rgba(0,0,0,0.1)] border-b border-white/10'
                  : 'bg-white/90 backdrop-blur-md shadow-lg border-b border-black/5'}`
               : 'py-5 bg-transparent'}`}
      >
         <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="flex items-center justify-between">
               {/* Logo */}
               <a href="/" className="relative group">
                  <span className="text-2xl font-bold text-accent-color hover:text-accent-light
                     transition-all duration-300 relative overflow-hidden group">
                     {t('nav.logoName')}
                     <span className="absolute bottom-0 left-0 w-full h-0.5 bg-accent-color/50 
                        transform origin-left scale-x-0 transition-transform duration-300 
                        group-hover:scale-x-100" />
                  </span>
               </a>

               {/* Desktop Menu */}
               <div className="hidden md:flex items-center justify-center flex-1 px-8">
                  <div className="relative p-2 w-[800px]">
                     <div className="relative flex items-center">
                        {/* Active Background Slider */}
                        <div
                           className="absolute h-10 transition-all duration-500 ease-spring"
                           style={{
                              width: `${100 / navigationItems.length}%`,
                              left: `${getItemPosition(activeTab)}%`,
                           }}
                        >
                           <div className="absolute inset-0 rounded-full overflow-hidden">
                              <div className={`absolute inset-0 ${isDark ? 'bg-accent-color/20' : 'bg-accent-light/20'}`} />
                              <div className="absolute inset-0 bg-gradient-to-r from-transparent 
                              via-accent-light/20 to-transparent animate-shine" />
                           </div>
                        </div>

                        {/* Navigation Items */}
                        {navigationItems.map((item) => (
                           <NavigationItem
                              key={item.id}
                              item={item}
                              isActive={activeTab === item.id}
                              isDark={isDark}
                              lang={lang}
                              onNavClick={handleNavClick}
                           />
                        ))}
                     </div>
                  </div>
               </div>

               {/* Theme & Language Controls */}
               <div className="hidden md:flex items-center gap-2">
                  <button
                     onClick={toggleTheme}
                     className="p-2.5 rounded-full bg-sidebar-bg/80 hover:bg-sidebar-hover
                        transition-all duration-300 text-text-light hover:text-accent-light
                        hover:scale-110 relative group overflow-hidden"
                     aria-label={isDark ? "Switch to light mode" : "Switch to dark mode"}
                  >
                     <div className="absolute inset-0 bg-gradient-to-r from-transparent 
                        via-white/10 to-transparent opacity-0 group-hover:opacity-100 
                        transition-opacity duration-300 animate-shine" />
                     {isDark ? <Sun className="w-4 h-4 relative z-10" /> : <Moon className="w-4 h-4 relative z-10" />}
                  </button>
                  <button
                     onClick={toggleLanguage}
                     className="p-2.5 rounded-full bg-sidebar-bg/80 hover:bg-sidebar-hover
                        transition-all duration-300 text-text-light hover:text-accent-light
                        hover:scale-110 relative group overflow-hidden"
                  >
                     <div className="absolute inset-0 bg-gradient-to-r from-transparent 
                        via-white/10 to-transparent opacity-0 group-hover:opacity-100 
                        transition-opacity duration-300 animate-shine" />
                     <Globe className="w-4 h-4 relative z-10" />
                  </button>
               </div>

               {/* Mobile Menu Button */}
               <button
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="md:hidden flex items-center justify-center w-10 h-10 rounded-full 
                     bg-sidebar-bg/80 hover:bg-sidebar-hover
                     transition-all duration-300 text-text-light hover:text-accent-light"
               >
                  <div className="relative w-5 h-5 flex items-center justify-center">
                     <Menu className={`absolute transition-all duration-300 
                        ${isMenuOpen ? 'opacity-0 rotate-90 scale-0' : 'opacity-100 rotate-0 scale-100'}`} />
                     <X className={`absolute transition-all duration-300
                        ${isMenuOpen ? 'opacity-100 rotate-0 scale-100' : 'opacity-0 -rotate-90 scale-0'}`} />
                  </div>
               </button>
            </div>
         </div>

         {/* Mobile Menu */}
         <div className={`fixed inset-x-0 top-[72px] p-4 md:hidden
            bg-sidebar-bg/95 backdrop-blur-lg border-t border-white/5
            transition-all duration-500 ease-in-out transform
            ${isMenuOpen ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0 pointer-events-none'}`}>
            <div className="space-y-2">
               {navigationItems.map((item, index) => (
                  <MobileNavigationItem
                     key={item.id}
                     item={item}
                     isActive={activeTab === item.id}
                     index={index}
                     onNavClick={handleNavClick}
                  />
               ))}

               {/* Theme and Language Controls for Mobile */}
               <div className="flex items-center gap-2 pt-4 mt-4 border-t border-white/10">
                  <button
                     onClick={toggleTheme}
                     className="flex-1 p-3 rounded-lg bg-sidebar-bg/80 hover:bg-sidebar-hover
                        transition-all duration-300 text-text-light hover:text-accent-light
                        relative group overflow-hidden"
                     aria-label={isDark ? "Switch to light mode" : "Switch to dark mode"}
                  >
                     <div className="absolute inset-0 bg-gradient-to-r from-transparent 
                        via-white/10 to-transparent opacity-0 group-hover:opacity-100 
                        transition-opacity duration-300 animate-shine" />
                     <div className={`flex items-center gap-3 justify-center ${lang === 'ar' ? 'flex-row-reverse' : ''}`}>
                        {isDark ? <Sun className="w-5 h-5 relative z-10" /> : <Moon className="w-5 h-5 relative z-10" />}
                        <span className="font-medium">
                           {isDark ? t('nav.themes.light') : t('nav.themes.dark')}
                        </span>
                     </div>
                  </button>
                  <button
                     onClick={toggleLanguage}
                     className="flex-1 p-3 rounded-lg bg-sidebar-bg/80 hover:bg-sidebar-hover
                        transition-all duration-300 text-text-light hover:text-accent-light
                        relative group overflow-hidden"
                  >
                     <div className="absolute inset-0 bg-gradient-to-r from-transparent 
                        via-white/10 to-transparent opacity-0 group-hover:opacity-100 
                        transition-opacity duration-300 animate-shine" />
                     <div className={`flex items-center gap-3 justify-center ${lang === 'ar' ? 'flex-row-reverse' : ''}`}>
                        <Globe className="w-5 h-5 relative z-10" />
                        <span className="font-medium">
                           {lang === 'en' ? 'العربية' : 'English'}
                        </span>
                     </div>
                  </button>
               </div>
            </div>
         </div>
      </nav>
   );
});

export default Navigation;