import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { en } from '../locales/en';
import { ar } from '../locales/ar';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
   // Lazy initialization for initial state to reduce re-computation
   const [lang, setLang] = useState(() => localStorage.getItem('language') || 'en');

   // Memoize translations to avoid recomputing on every render
   const translations = useMemo(() => ({ en, ar }), []);

   // Save the language setting in localStorage only when it changes
   useEffect(() => {
      localStorage.setItem('language', lang);
   }, [lang]);

   // Memoize the toggle function to avoid unnecessary re-renders
   const toggleLanguage = useCallback(() => {
      setLang((prev) => (prev === 'en' ? 'ar' : 'en'));
   }, []);

   // Optimize translation lookup by using memoized function
   const t = useCallback(
      (key) => {
         return key.split('.').reduce((acc, k) => (acc && acc[k] !== undefined ? acc[k] : key), translations[lang]);
      },
      [lang, translations]
   );

   // Memoize context value to prevent unnecessary re-renders
   const contextValue = useMemo(() => ({ lang, toggleLanguage, t }), [lang, toggleLanguage, t]);

   return (
      <LanguageContext.Provider value={contextValue}>
         <div dir={lang === 'ar' ? 'rtl' : 'ltr'}>{children}</div>
      </LanguageContext.Provider>
   );
};

export const useLanguage = () => {
   const context = useContext(LanguageContext);
   if (!context) {
      throw new Error('useLanguage must be used within a LanguageProvider');
   }
   return context;
};
