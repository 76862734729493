export const en = {
   nav: {
      home: "Home",
      projects: "Projects",
      about: "About",
      courses: "Courses",
      contact: "Contact",
      logoName: "Esam",
      themes: {
         light: "Light Mode",
         dark: "Dark Mode"
      }
   },
   hero: {
      greeting: "Hi, I'm",
      name: "Esam Jaafar",
      title: "Full Stack Developer",
      description: "Full Stack Developer, Mobile Developer, AI Engineer & UI/UX Designer. Crafting digital experiences with code and creativity. Specialized in building exceptional applications that combine innovative design with powerful functionality.",
      cta: "View My Work",
      learnButton: "Learn More",
      projects: "Projects",
      experience: "Experience",
      experienceYears: "Years",
      clients: "Clients",
      hire: "Hire Me",
      technologies: "Technologies"
   },
   overview: {
      name: "Esam Jaafar",
      title: "Full Stack Developer & AI Engineer",
      email: "jfr3sam@gmail.com",
      phone: "+966 548 595 151",
      location: "Dhahran, Saudi Arabia",
      github: "github.com/jfr3sam",
      linkedin: "linkedin.com/in/jfr3sam",
      twitter: "https://x.com/jfr3sam",
      website: "esamjaafar.com",
      download: "Download CV",
      labels: {
         email: "Email",
         phone: "Phone",
         location: "Location",
         github: "Github",
         linkedin: "LinkedIn",
         twitter: "Twitter",
         website: "Portfolio",
      },
      education: {
         degree: "Bachelor of Science in Computer Science",
         university: "King Fahd University of Petroleum and Minerals",
         date: "2018 - 2024",
         location: "Dhahran, Saudi Arabia"
      },
      about: {
         title: "About Me",
         description: "Full Stack Developer, Mobile Developer, AI Engineer & UI/UX Designer. Crafting digital experiences with code and creativity. Specialized in building exceptional applications that combine innovative design with powerful functionality.",
      },
      status: {
         title: "Quick Stats",
         labels: {
            one: "AI Projects",
            two: "Web Applications",
            three: "Backend Systems",
            four: "Certificates",
         }
      },
      skills: {
         title: "Core Skills",
      }
   },
   skills: {
      frontendTitle: "Frontend Development",
      backendTitle: "Backend Development",
      MobileTitle: "Mobile Development & AI",
      levels: {
         expert: {
            label: "Expert",
            description: "Deep understanding, ability to architect solutions and guide others",
            years: "5+ years",
            projects: "20+ projects"
         },
         advanced: {
            label: "Advanced",
            description: "Strong understanding, ability to handle complex challenges independently",
            years: "3-5 years",
            projects: "10-20 projects"
         },
         proficient: {
            label: "Proficient",
            description: "Comfortable with most aspects, regular usage",
            years: "1-3 years",
            projects: "5-10 projects"
         },
         learning: {
            label: "Learning",
            description: "Basic understanding, active learning",
            years: "Less than 1 year",
            projects: "1-5 projects"
         }
      }
   },
   certificate: {
      button: "View Certificate",
      id: "Credential ID",
      certificates: {
         one: {
            name: "Certificate of Appreciation",
            issuer: "Mawhiba - KFUPM",
            date: "2023",
            credential: "COA-2023",
            url: "/certificates/Mahwiba-KFUPM-2022.pdf"
         },
         two: {
            name: "Research Skills",
            issuer: "King Fahd University of Petroleum and Minerals",
            date: "2022",
            credential: "RS-2022",
            url: "/certificates/Mahwiba-KFUPM-2022.pdf"
         },
         three: {
            name: "Data Analysis with Python",
            issuer: "King Fahd University of Petroleum and Minerals",
            date: "2021",
            credential: "DAP-2021",
            url: "/certificates/Mahwiba-KFUPM-2022.pdf"
         },
         four: {
            name: "Advanced Features in Excel",
            issuer: "King Fahd University of Petroleum and Minerals",
            date: "2021",
            credential: "AFE-2021",
            url: "/certificates/Mahwiba-KFUPM-2022.pdf"
         },
      },
      status: {
         certificatesNumber: "Certificates",
         skillsNumber: "Skills",
         experienceNumber: "Experience",
         recognitionNumber: "Recognition"
      }
   },
   about: {
      title: "About Me",
      subtitle: "Crafting Digital Experiences",
      description: "Passionate developer with a keen eye for detail and a commitment to creating seamless, user-friendly applications.",
      tabs: {
         overview: "Overview",
         skills: "Skills",
         experience: "Experience",
         education: "Education",
         certificates: "Certificates",
         resume: "Resume"
      },
      frontendTitle: "Frontend Development",
      frontendDescription: "Expert in React, Vue, and modern JavaScript",
      backendTitle: "Backend Development",
      backendDescription: "Node.js, Python, and database management",
      designTitle: "UI/UX Design",
      designDescription: "Creating beautiful and intuitive interfaces",
      performanceTitle: "Web Performance",
      performanceDescription: "Optimization and best practices expert",
      experience: "Years Experience",
      years: "years",
      projects: "Projects",
      completed: "completed",
      clients: "Satisfied Clients",
      satisfied: "satisfied",
      technologies: "Technologies",
      mastered: "mastered",
      resume: {
         title: "Professional Resume",
         download: "Download CV",
         summary: "Professional Summary",
         experience: "Work Experience",
         education: "Education",
         skills: "Technical Skills",
         email: "Email",
         phone: "Phone",
         location: "Location",
         website: "Website"
      },
      certificates: {
         title: "Certifications & Achievements",
         credentialId: "Credential ID",
         issuer: "Issuing Organization",
         date: "Issue Date",
         view: "View Certificate",
         verify: "Verify",
         expires: "Expires"
      },
      education: {
         degree: "Degree",
         institution: "Institution",
         duration: "Duration",
         location: "Location",
         gpa: "GPA",
         courses: "Key Courses",
         achievements: "Achievements"
      }
   },
   courses: {
      title: "My Courses",
      subtitle: "Explore top rated courses",
      reviews: "reviews",
      rateThis: "Rate this course",
      shareYourExperience: "Share your learning experience",
      feedbackPlaceholder: "Your feedback...",
      submitRating: "Submit Rating",
      description: "Course Description",
      agenda: "Course Agenda",
      startLearning: "Start Learning",
      visitPlatform: "Visit Platform",
      duration: "Duration",
      enrolled: "Enrolled",
      certificate: "Certificate",
      level: "Level",
      students: "students",
      viewAgenda: "View Agenda",
      prerequisites: "Prerequisites",
      learningOutcomes: "Learning Outcomes",
      tools: "Tools & Technologies",
      projects: "Projects",
      instructors: "Course Instructor",
      topics: "Topics",
      share: {
         title: "Share Course",
         twitter: "Twitter",
         facebook: "Facebook",
         linkedin: "LinkedIn",
         copyLink: "Copy Link",
         copied: "Link Copied!",
         url: "Link"
      },
      availability: {
         all: "All Courses",
         available: "Available",
         comingSoon: "Coming Soon",
         notAvailable: "Not Available"
      },
      comingSoonMessage: "This course will be available soon!",
      notAvailableMessage: "This course is currently not available.",
      filters: "Filter Courses",
      price: {
         free: "Free",
         paid: "Paid",
         trial: "Free Trial"
      }
   },
   projects: {
      title: "My Projects",
      subtitle: "Recent Work",
      viewProject: "View Project",
      liveDemo: "Live Demo",
      sourceCode: "Source Code",
      private: "Private",
      tabs: {
         overview: "Overview",
         features: "Features",
         technical: "Technical"
      },
      types: {
         web: "Web",
         mobile: "Mobile"
      },
      projectAccessType: {
         private: "Private",
         public: "Public"
      },
      projectType: {
         all: "All",
         web: "Web",
         mobile: "Mobile"
      },
      stats: {
         released: "Released",
         version: "Version",
         teamSize: "Team Size",
         members: "members"
      },
      sections: {
         keyFeatures: "Key Features",
         projectStats: "Project Stats",
         technicalStack: "Technical Stack",
         architecture: "Architecture Overview",
         performance: "Performance Metrics"
      },
      technologies: "Technologies",
      requestAccess: "Request Project Access",
      privateProject: "Private Project",
      privateProjectDescription: "This is a private project. To get access, please fill out the form below and we'll get back to you soon.",
      sendRequest: "Send Request",
      requestSuccess: "Request sent successfully!",
      requestError: "Something went wrong. Please try again.",
      company: "Company Name",
      contact: {
         name: "Name",
         email: "Email",
         message: "Message",
         nameRequired: "Your name is required.",
         emailRequired: "Your email is required.",
         emailInvalid: "Please enter a valid email address.",
         messageRequired: "A message is required."
      },
      requestMessage: "Hello,\n\nI am interested in getting access to the {projectName} project.\n\nI would appreciate if you could provide more information about:\n- Project documentation\n- Access requirements\n- Terms of use\n- Potential collaboration opportunities\n\nThank you for your consideration.\n\nBest regards,"
   },
   contact: {
      getInTouch: "Get In Touch",
      title: "Let's Connect",
      description: "Have a project in mind or want to discuss opportunities? I'd love to hear from you. Feel free to reach out through any of the following channels or send me a direct message.",
      name: "Your Name",
      email: "Your Email",
      subject: "Subject",
      message: "Your Message",
      send: "Send Message",
      sending: "Sending...",
      success: "Message sent successfully!",
      error: "Oops! Something went wrong. Please try again.",
      location: "Location",
      phone: "Phone",
      availability: "Availability",
      hours: "Sun-Thu: 8 AM - 9 PM",
      emailLabel: "Email Address",
      phoneLabel: "Phone Number",
      address: "Dhahran, Eastern Region, KSA",
      emailAddress: "jfr3sam@gmail.com",
      phoneNumber: "+966 055 267 8806"
   },
   footer: {
      resources: "Resources",
      courses: "Courses",
      projects: "Projects",
      publications: "Publications",
      achievements: "Achievements",
      newsletter: "Newsletter",
      subscribeText: "Subscribe to my newsletter for the latest updates.",
      emailPlaceholder: "Enter your email",
      subscribe: "Subscribe",
      subscribing: "Subscribing...",
      subscribed: "Subscribed!",
      rights: "All rights reserved",
      made: "Made with",
      by: "by"
   }
};