import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../contexts/LanguageContext';
import { useTheme } from '../../contexts/ThemeContext';
import {
   Github, Twitter, Linkedin, Mail, Heart,
   BookOpen, Code, GraduationCap, Award, Sparkles, ArrowRight
} from 'lucide-react';

const SocialButton = ({ href, icon: Icon, label, delay = 0, type = 'github' }) => {
   const colorMap = {
      github: 'from-gray-600/20 to-gray-400/20',
      twitter: 'from-blue-400/20 to-blue-300/20',
      linkedin: 'from-blue-700/20 to-blue-500/20',
      email: 'from-red-500/20 to-orange-400/20'
   };

   return (
      <a href={href}
         target="_blank"
         rel="noopener noreferrer"
         className="social-icon w-10 h-10 rounded-xl bg-white/5 flex items-center justify-center group 
                  relative overflow-hidden transition-all duration-300 hover:scale-110 hover:-translate-y-1
                  animate-float-delayed"
         style={{ animationDelay: `${delay}s` }}>
         <div className={`absolute inset-0 bg-gradient-to-tr ${colorMap[type]} opacity-30 animate-pulse-slow`} />
         <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/30 to-transparent animate-shine" />
         <div className={`absolute -inset-1 bg-gradient-to-tr ${colorMap[type]} opacity-20 blur-sm animate-pulse-glow`} />
         <Icon className="w-5 h-5 text-gray-400 group-hover:text-white relative z-10" />
      </a>
   );
};

const ResourceLink = ({ icon: Icon, href, label }) => {
   const { isDark } = useTheme();
   const { lang } = useLanguage();

   const handleClick = (e) => {
      e.preventDefault();
      const targetId = href.replace('#', '');
      const element = document.getElementById(targetId);

      if (element) {
         const navHeight = document.querySelector('nav')?.offsetHeight || 0;
         const elementPosition = element.getBoundingClientRect().top;
         const offsetPosition = elementPosition + window.scrollY - navHeight;

         // Create smooth scroll animation
         window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
         });

         // Add focus effect after scrolling
         const scrollTimeout = setTimeout(() => {
            element.style.transition = 'outline 0.3s ease';
            element.style.outline = '2px solid rgba(79, 209, 197, 0.5)';

            setTimeout(() => {
               element.style.outline = 'none';
            }, 1000);
         }, 800); // Wait for scroll to complete

         return () => clearTimeout(scrollTimeout);
      }
   };

   const isInternalLink = href.startsWith('#');

   const linkProps = isInternalLink ? {
      onClick: handleClick,
      href
   } : {
      href,
      target: "_blank",
      rel: "noopener noreferrer"
   };

   return (
      <motion.a
         {...linkProps}
         className={`group flex items-center gap-3 p-3 rounded-xl transition-all duration-300 
            relative overflow-hidden
            ${isDark ? 'hover:bg-white/5' : 'hover:bg-accent-color/5'}`}
         whileHover={{ scale: 1.02, x: lang === 'ar' ? -5 : 5 }}
         whileTap={{ scale: 0.98 }}

      >
         <div className="absolute inset-0 bg-gradient-to-r from-accent-color/10 via-transparent to-accent-color/10 
            opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

         <motion.div
            className={`flex items-center justify-center w-10 h-10 rounded-lg
               transition-all duration-300 group-hover:scale-110 bg-gradient-to-br relative z-10
               ${isDark ? 'from-accent-color/10 to-primary-color/10' : 'from-accent-color/20 to-primary-color/20'}`}
            whileHover={{ rotate: 5 }}
         >
            <div className="absolute inset-0 bg-accent-color/20 rounded-lg blur-md opacity-0 
               group-hover:opacity-100 transition-opacity duration-300" />
            <Icon className="w-5 h-5 text-accent-color relative z-10" />
         </motion.div>

         <span className={`font-medium transition-colors duration-300 relative z-10
            ${isDark ? 'text-gray-300 group-hover:text-accent-light' : 'text-gray-600 group-hover:text-accent-color'}`}>
            {label}
         </span>

         <ArrowRight className={`w-4 h-4 opacity-0 transition-all duration-300
            ${isDark ? 'text-accent-light' : 'text-accent-color'} 
            ${lang === 'ar' ? 'rotate-180 group-hover:-translate-x-2' : 'group-hover:translate-x-2'}  
            group-hover:opacity-100 relative z-10`}
         />

         <div className="absolute inset-0 opacity-0 group-hover:opacity-100">
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent 
               -translate-x-full group-hover:translate-x-full transition-all duration-1000" />
         </div>
      </motion.a>
   );
};

const Footer = () => {
   const { t, lang } = useLanguage();
   const { isDark } = useTheme();
   const [email, setEmail] = useState('');
   const [subscribeStatus, setSubscribeStatus] = useState('idle'); // idle, submitting, success, error

   const handleSubscribe = (e) => {
      e.preventDefault();
      if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) return;

      setSubscribeStatus('submitting');
      // Simulate API call
      setTimeout(() => {
         setSubscribeStatus('success');
         setEmail('');
         // Reset after 3 seconds
         setTimeout(() => setSubscribeStatus('idle'), 3000);
      }, 1500);
   };

   const resources = [
      { icon: GraduationCap, label: t('footer.courses'), href: '#courses' },
      { icon: Code, label: t('footer.projects'), href: '#projects' },
      { icon: BookOpen, label: t('footer.publications'), href: '/publications' },
      { icon: Award, label: t('footer.achievements'), href: '/achievements' }
   ];

   const socialLinks = [
      { icon: Github, href: 'github.com/jfr3sam', label: 'GitHub', type: 'github', delay: 0 },
      { icon: Twitter, href: 'https://x.com/jfr3sam', label: 'Twitter', type: 'twitter', delay: 0.2 },
      { icon: Linkedin, href: 'linkedin.com/in/jfr3sam', label: 'LinkedIn', type: 'linkedin', delay: 0.4 },
      { icon: Mail, href: 'mailto:jfr3sam@gmail.com', label: 'Email', type: 'email', delay: 0.6 }
   ];

   return (
      <footer className="relative pt-20">
         {/* Animated Background */}
         <div className="absolute inset-0 overflow-hidden">
            <div className={`absolute inset-0 ${isDark ? 'bg-[#0B1120]' : 'bg-gray-50'}`} />
            <div className="absolute inset-0 bg-grid-white/5 opacity-10" />

            {/* Gradient Orbs */}
            <div className="absolute top-0 left-1/4 w-96 h-96 bg-accent-color/20 rounded-full blur-3xl opacity-20" />
            <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-primary-color/20 rounded-full blur-3xl opacity-20" />
         </div>

         {/* Main Content */}
         <div className="relative">
            <div className={`border-t ${isDark ? 'border-white/10' : 'border-gray-200'}`}>
               <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
                     {/* Brand Section */}
                     <div className="space-y-6">
                        <div>
                           <h3 className="text-3xl font-bold bg-gradient-to-r from-accent-color to-accent-light 
                              bg-clip-text text-transparent mb-4">
                              {t('nav.logoName')}
                           </h3>
                           <p className={`text-lg ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                              {t('hero.description')}
                           </p>
                        </div>
                        <div className="flex gap-4">
                           {socialLinks.map((social, index) => (
                              <SocialButton key={index} {...social} />
                           ))}
                        </div>
                     </div>

                     {/* Resources Section */}
                     <div className="lg:col-span-2">
                        <h4 className="text-xl font-bold mb-6">
                           <div className="flex items-center gap-2">
                              <div className={`w-6 h-0.5 ${isDark ? 'bg-accent-color' : 'bg-primary-color'}`} />
                              <span className="bg-gradient-to-r from-accent-color to-accent-light bg-clip-text text-transparent">
                                 {t('footer.resources')}
                              </span>
                           </div>
                        </h4>
                        <div className={`grid grid-cols-1 sm:grid-cols-2 gap-2 
                           ${isDark ? 'text-gray-400' : 'text-gray-600'} 
                           ${lang === 'ar' ? 'text-right' : 'text-left'}`}>
                           {resources.map((resource, index) => (
                              <ResourceLink key={index} {...resource} />
                           ))}
                        </div>
                     </div>

                     {/* Newsletter Section */}
                     <div>
                        <h4 className="text-xl font-bold mb-6">
                           <div className="flex items-center gap-2">
                              <div className={`w-6 h-0.5 ${isDark ? 'bg-accent-color' : 'bg-primary-color'}`} />
                              <span className="bg-gradient-to-r from-accent-color to-accent-light bg-clip-text text-transparent">
                                 {t('footer.newsletter')}
                              </span>
                           </div>
                        </h4>
                        <div className="space-y-4">
                           <p className={isDark ? 'text-gray-400' : 'text-gray-600'}>
                              {t('footer.subscribeText')}
                           </p>
                           <form onSubmit={handleSubscribe} className="space-y-3">
                              <div className="relative group">
                                 <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className={`w-full px-4 py-3 bg-white/5 border border-white/10 rounded-xl 
                                       focus:ring-2 focus:ring-accent-color focus:border-transparent 
                                       outline-none text-white placeholder-gray-500
                                       transition-all duration-300 
                                       group-hover:bg-white/10
                                       group-hover:border-white/20`}
                                    placeholder={t('footer.emailPlaceholder')}
                                    disabled={subscribeStatus !== 'idle'}
                                 />
                                 {/* Input background effects */}
                                 <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-primary-color/10 
                                    to-accent-color/10 opacity-0 group-hover:opacity-100 
                                    transition-opacity duration-300 pointer-events-none" />
                              </div>

                              <button
                                 type="submit"
                                 disabled={subscribeStatus !== 'idle'}
                                 className={`w-full relative overflow-hidden px-4 py-3 rounded-xl font-medium
                                    transition-all duration-300 transform
                                    ${subscribeStatus === 'idle'
                                       ? 'bg-gradient-to-r from-accent-color to-primary-color text-white hover:scale-105'
                                       : 'bg-gray-500 cursor-not-allowed'}`}
                              >
                                 {/* Button content based on status */}
                                 {subscribeStatus === 'idle' && (
                                    <span className="relative z-10 flex items-center justify-center gap-2">
                                       <Mail className="w-5 h-5" />
                                       {t('footer.subscribe')}
                                    </span>
                                 )}
                                 {subscribeStatus === 'submitting' && (
                                    <span className="flex items-center justify-center gap-2">
                                       <svg className="animate-spin h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                                       </svg>
                                       {t('footer.subscribing')}
                                    </span>
                                 )}
                                 {subscribeStatus === 'success' && (
                                    <span className="flex items-center justify-center gap-2 text-white">
                                       <Sparkles className="w-5 h-5" />
                                       {t('footer.subscribed')}
                                    </span>
                                 )}

                                 {/* Animated background effects */}
                                 <div className="absolute inset-0 bg-gradient-to-r from-transparent 
                                    via-white/20 to-transparent translate-x-[-100%] group-hover:translate-x-[100%] 
                                    transition-transform duration-700" />
                              </button>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            {/* Footer Bottom */}
            <div className={`border-t backdrop-blur-sm
               ${isDark ? 'border-white/10 bg-[#1E293B]/50' : 'border-gray-200 bg-white/60'}`}>
               <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
                  <div className={`flex justify-center items-center gap-4 text-sm
                     ${lang === 'ar' ? 'flex-row-reverse' : ''}`}>
                     <div className={`flex items-center gap-2 
                        ${isDark ? 'text-gray-400' : 'text-gray-600'}
                        ${lang === 'ar' ? 'flex-row' : ''}`}>
                        <span className={`flex items-center gap-1 ${lang === 'ar' ? 'flex-row' : ''}`}>
                           {t('footer.made')} <Heart className="w-4 h-4 text-red-500 animate-pulse" />
                           {t('footer.by')} {t('hero.name')}
                        </span>
                        © {new Date().getFullYear()} {t('nav.logoName')}
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </footer>
   );
};

export default Footer;