export const ar = {
   nav: {
      home: "الرئيسية",
      projects: "المشاريع",
      about: "عني",
      contact: "اتصل بي",
      courses: "الدورات",
      logoName: "عصام",
      themes: {
         light: "الوضع الفاتح",
         dark: "الوضع الداكن"
      }
   },
   hero: {
      greeting: "مرحباً، أنا",
      name: "عصام جعفر",
      title: "مطور ويب متكامل",
      description: "مطور ويب متكامل، مطور تطبيقات، مهندس ذكاء اصطناعي ومصمم واجهات مستخدم. أصمم تجارب رقمية بالبرمجة والإبداع. متخصص في بناء تطبيقات استثنائية تجمع بين التصميم المبتكر والوظائف القوية.",
      cta: "شاهد أعمالي",
      learnButton: "تعرف على المزيد",
      projects: "المشاريع",
      experience: "الخبرة",
      experienceYears: "سنوات",
      clients: "العملاء",
      hire: "وظفني",
      technologies: "التكنولوجيات"
   },
   overview: {
      name: "عصام جعفر",
      title: "مطور برمجيات كاملة ومهندس ذكاء اصطناعي",
      email: "jfr3sam@gmail.com",
      phone: "151 595 548 966+",
      location: "الظهران، المملكة العربية السعودية",
      github: "github.com/jfr3sam",
      linkedin: "linkedin.com/in/jfr3sam",
      twitter: "https://x.com/jfr3sam",
      website: "esamjaafar.com",
      download: "تحميل السيرة الذاتية",
      labels: {
         email: "البريد الألكتروني",
         phone: "رقم الجوال",
         location: "الموقع",
         github: "Github",
         linkedin: "Linkedin",
         twitter: "توتير",
         website: "ملفي الشخصي",
      },
      education: {
         degree: "بكالوريوس العلوم في علوم الكمبيوتر",
         university: "جامعة الملك فهد للبترول والمعادن",
         date: "2018 - 2024",
         location: "الظهران، المملكة العربية السعودية"
      },
      about: {
         title: "عني",
         description: "مطور ويب متكامل، مطور تطبيقات، مهندس ذكاء اصطناعي ومصمم واجهات مستخدم. أصمم تجارب رقمية بالبرمجة والإبداع. متخصص في بناء تطبيقات استثنائية تجمع بين التصميم المبتكر والوظائف القوية.",
      },
      status: {
         title: "إحصائيات سريعة",
         labels: {
            one: "مشاريع الذكاء الاصطناعي",
            two: "تطبيقات الويب",
            three: "أنظمة الخلفية",
            four: "الشهادات",
         }
      },
      skills: {
         title: "المهارات الأساسية",
      }
   },
   "skills": {
      "frontendTitle": "تطوير الواجهة الأمامية",
      "backendTitle": "تطوير الواجهة الخلفية",
      "MobileTitle": "تطوير الأجهزة المحمولة والذكاء الاصطناعي",
      "levels": {
         "expert": {
         "label": "خبير",
         "description": "فهم عميق، القدرة على تصميم الحلول وتوجيه الآخرين",
         "years": "+5 سنوات",
         "projects": "+20 مشروع"
         },
         "advanced": {
         "label": "متقدم",
         "description": "فهم قوي، القدرة على معالجة التحديات المعقدة بشكل مستقل",
         "years": "3-5 سنوات",
         "projects": "10-20 مشروع"
         },
         "proficient": {
         "label": "متمكن",
         "description": "مرتاح مع معظم الجوانب، استخدام منتظم",
         "years": "1-3 سنوات",
         "projects": "5-10 مشاريع"
         },
         "learning": {
         "label": "متعلم",
         "description": "فهم أساسي، تعلم نشط",
         "years": "أقل من سنة",
         "projects": "1-5 مشاريع"
         }
      }
   },
   certificate: {
      button: "عرض الشهادة",
      id: "معرف الاعتماد",
      certificates: {
         one: {
            name: "شهادة تقدير",
            issuer: "موهبة - جامعة الملك فهد للبترول والمعادن",
            date: "2023",
            credential: "COA-2023",
            url: "/certificates/Mahwiba-KFUPM-2022.pdf"
         },
         two: {
            name: "مهارات البحث",
            issuer: "جامعة الملك فهد للبترول والمعادن",
            date: "2022",
            credential: "RS-2022",
            url: "/certificates/Mahwiba-KFUPM-2022.pdf"
         },
         three: {
            name: "تحليل البيانات باستخدام بايثون",
            issuer: "جامعة الملك فهد للبترول والمعادن",
            date: "2021",
            credential: "DAP-2021",
            url: "/certificates/Mahwiba-KFUPM-2022.pdf"
         },
         four: {
            name: "الميزات المتقدمة في إكسل",
            issuer: "جامعة الملك فهد للبترول والمعادن",
            date: "2021",
            credential: "AFE-2021",
            url: "/certificates/Mahwiba-KFUPM-2022.pdf"
         },
      },
      status: {
         certificatesNumber : "الشهادات",
         skillsNumber: "المهارات",
         experienceNumber : "الخبرة",
         recognitionNumber: "التعرُّف"
      }
   },
   about: {
      title: "عنّي",
      subtitle: "صناعة التجارب الرقمية",
      description: "مطور شغوف ذو عين حريصة على التفاصيل وملتزم بإنشاء تطبيقات سلسة وسهلة الاستخدام.",

      // Tab labels
      tabs: {
         overview: "نظرة عامه",
         skills: "المهارات",
         experience: "الخبرات",
         education: "التعليم",
         certificates: "الشهادات",
         resume: "السيرة الذاتية"
      },

      // Skills section
      frontendTitle: "تطوير الواجهة الأمامية",
      frontendDescription: "خبير في React و Vue و JavaScript الحديثة",
      backendTitle: "تطوير الواجهة الخلفية",
      backendDescription: "Node.js و Python وإدارة قواعد البيانات",
      designTitle: "تصميم واجهة المستخدم",
      designDescription: "إنشاء واجهات جميلة وبديهية",
      performanceTitle: "أداء الويب",
      performanceDescription: "خبير في التحسين وأفضل الممارسات",

      // Stats section
      experience: "سنوات الخبرة",
      years: "سنوات",
      projects: "المشاريع",
      completed: "المنجزة",
      clients: "العملاء",
      satisfied: "الراضين",
      technologies: "التقنيات",
      mastered: "المتقنة",

      // Resume section
      resume: {
         title: "السيرة المهنية",
         download: "تحميل السيرة الذاتية",
         summary: "الملخص المهني",
         experience: "الخبرات العملية",
         education: "التعليم",
         skills: "المهارات التقنية",
         email: "البريد الإلكتروني",
         phone: "الهاتف",
         location: "الموقع",
         website: "الموقع الإلكتروني"
      },

      // Certificates section
      certificates: {
         title: "الشهادات والإنجازات",
         credentialId: "رقم الشهادة",
         issuer: "الجهة المانحة",
         date: "تاريخ الإصدار",
         view: "عرض الشهادة",
         verify: "التحقق",
         expires: "تاريخ الانتهاء"
      },

      // Education section
      education: {
         degree: "الدرجة العلمية",
         institution: "المؤسسة التعليمية",
         duration: "المدة",
         location: "الموقع",
         gpa: "المعدل التراكمي",
         courses: "المواد الرئيسية",
         achievements: "الإنجازات"
      }
   },
   courses: {
      title: "دوراتي",
      subtitle: "استكشف أعلى الدورات تقييماً",
      reviews: "تقييمات",
      rateThis: "قيّم هذه الدورة",
      shareYourExperience: "شارك تجربتك في التعلم",
      feedbackPlaceholder: "ملاحظاتك...",
      submitRating: "أرسل التقييم",
      description: "وصف الدورة",
      agenda: "الجدول الزمني للدورة",
      startLearning: "ابدأ التعلم",
      visitPlatform: "زيارة المنصة",
      duration: "المدة",
      enrolled: "المسجلين",
      certificate: "الشهادة",
      level: "المستوى",
      students: "طلاب",
      viewAgenda: "عرض الجدول الزمني",
      prerequisites: "المتطلبات الأساسية",
      learningOutcomes: "مخرجات التعلم",
      tools: "الأدوات والتقنيات",
      projects: "المشاريع",
      instructors: "مدرب الدورة",
      topics: "المواضيع",

      // Share functionality
      share: {
         title: "مشاركة الدورة",
         twitter: "تويتر",
         facebook: "فيسبوك",
         linkedin: "لينكدإن",
         copyLink: "نسخ الرابط",
         copied: "تم نسخ الرابط!",
         url: "الرابط"
      },

      // Availability states
      availability: {
         all: "جميع الدورات",
         available: "متوفر",
         comingSoon: "قريباً",
         notAvailable: "غير متوفر"
      },

      // Messages
      comingSoonMessage: "هذه الدورة ستكون متاحة قريباً!",
      notAvailableMessage: "هذه الدورة غير متوفرة حالياً.",

      // Filters
      filters: "تصفية الدورات",

      // Price related
      price: {
         free: "مجاني",
         paid: "مدفوع",
         trial: "تجريبي مجاني"
      }
   },
   "projects": {
      "title": "مشاريعي",
      "subtitle": "أحدث الأعمال",
      "viewProject": "عرض المشروع",
      "liveDemo": "عرض حي",
      "sourceCode": "الكود المصدري",
      "private": "خاص",
      "tabs": {
         "overview": "نظرة عامة",
         "features": "المميزات",
         "technical": "التفاصيل التقنية"
      },
      "types": {
         "web": "ويب",
         "mobile": "جوال"
      },
      "projectAccessType": {
         "private": "خاص",
         "public": "عام"
      },
      "projectType": {
         "all": "الكل",
         "web": "ويب",
         "mobile": "جوال"
      },
      "stats": {
         "released": "تاريخ الإصدار",
         "version": "الإصدار",
         "teamSize": "حجم الفريق",
         "members": "أعضاء"
      },
      "sections": {
         "keyFeatures": "المميزات الرئيسية",
         "projectStats": "إحصائيات المشروع",
         "technicalStack": "التقنيات المستخدمة",
         "architecture": "نظرة على الهيكلة",
         "performance": "مقاييس الأداء"
      },
      "technologies": "التقنيات",
      "requestAccess": "طلب الوصول للمشروع",
      "privateProject": "مشروع خاص",
      "privateProjectDescription": "هذا مشروع خاص. للحصول على حق الوصول، يرجى ملء النموذج أدناه وسنرد عليك قريباً.",
      "sendRequest": "إرسال الطلب",
      "requestSuccess": "تم إرسال الطلب بنجاح!",
      "requestError": "حدث خطأ ما. يرجى المحاولة مرة أخرى.",
      "company": "اسم الشركة",
      "contact": {
         "name": "الاسم",
         "email": "البريد الإلكتروني",
         "message": "الرسالة",
         "nameRequired": "الاسم مطلوب.",
         "emailRequired": "البريد الإلكتروني مطلوب.",
         "emailInvalid": "يرجى إدخال بريد إلكتروني صالح.",
         "messageRequired": "الرسالة مطلوبة."
      },
      "requestMessage": "مرحباً،\n\nأود الحصول على إمكانية الوصول إلى مشروع {projectName}.\n\nسأكون ممتناً لو تمكنتم من تزويدي بمزيد من المعلومات حول:\n- وثائق المشروع\n- متطلبات الوصول\n- شروط الاستخدام\n- فرص التعاون المحتملة\n\nشكراً لاهتمامكم.\n\nمع أطيب التحيات،"
   },
   contact: {
      getInTouch: "تواصل معي",
      title: "لنكن على اتصال",
      description: "لديك مشروع في ذهنك أو ترغب في مناقشة فرص؟ يسعدني سماع ذلك منك. لا تتردد في التواصل عبر أي من القنوات التالية أو أرسل لي رسالة مباشرة.",
      name: "اسمك",
      email: "بريدك الإلكتروني",
      subject: "الموضوع",
      message: "رسالتك",
      send: "أرسل الرسالة",
      sending: "جاري الإرسال...",
      success: "تم إرسال الرسالة بنجاح!",
      error: "عذرًا! حدث خطأ ما. يرجى المحاولة مرة أخرى.",
      location: "الموقع",
      phone: "الهاتف",
      availability: "أوقات التواجد",
      hours: "الأحد - الخميس: 8 صباحًا - 9 مساءً",
      emailLabel: "البريد الإلكتروني",
      phoneLabel: "رقم الهاتف",
      address: "الظهران، المنطقة الشرقية، المملكة العربية السعودية",
      emailAddress: "jfr3sam@gmail.com",
      phoneNumber: "151 595 548 966+"
   },
   footer: {
      resources: "الموارد",
      courses: "الدورات",
      projects: "المشاريع",
      publications: "المنشورات",
      achievements: "الإنجازات",
      newsletter: "النشرة البريدية",
      subscribeText: "اشترك في النشرة البريدية للحصول على آخر المستجدات.",
      emailPlaceholder: "أدخل بريدك الإلكتروني",
      subscribe: "اشترك",
      subscribing: "جاري الاشتراك...",
      subscribed: "تم الاشتراك!",
      rights: "جميع الحقوق محفوظة",
      made: "صنع بـ",
      by: "بواسطة"
   }
};