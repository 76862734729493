import React, { Suspense, useEffect, useCallback, memo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ThemeProvider } from './contexts/ThemeContext';
import { LanguageProvider } from './contexts/LanguageContext';
import Navigation from './components/layout/Navigation';
import Footer from './components/layout/Footer';

// Optimized section imports with better chunk naming
const SECTIONS = {
  Hero: React.lazy(() => 
    import(/* webpackChunkName: "hero" */ './components/sections/Hero')
      .then(module => ({ default: module.default }))
  ),
  About: React.lazy(() => 
    import(/* webpackChunkName: "about" */ './components/sections/About')
      .then(module => ({ default: module.default }))
  ),
  Courses: React.lazy(() => 
    import(/* webpackChunkName: "courses" */ './components/sections/Courses')
      .then(module => ({ default: module.default }))
  ),
  Projects: React.lazy(() => 
    import(/* webpackChunkName: "projects" */ './components/sections/Projects')
      .then(module => ({ default: module.default }))
  ),
  Contact: React.lazy(() => 
    import(/* webpackChunkName: "contact" */ './components/sections/Contact')
      .then(module => ({ default: module.default }))
  ),
};

// Memoized loader component
const SectionLoader = memo(() => (
  <div className="w-full h-[200px] flex items-center justify-center">
    <motion.div
      animate={{ rotate: 360 }}
      transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
      className="w-8 h-8 border-3 border-accent-color border-t-transparent rounded-full"
    />
  </div>
));

SectionLoader.displayName = 'SectionLoader';

// Optimized Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="text-center p-4">
          <h2 className="text-xl text-status-error">Something went wrong.</h2>
          <button 
            onClick={() => window.location.reload()}
            className="mt-2 px-4 py-2 bg-accent-color text-white rounded-lg"
          >
            Retry
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

// Optimized LazySection component with useCallback
const LazySection = memo(({ children }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const sectionRef = React.useRef(null);

  const observerCallback = useCallback(
    ([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
      }
    },
    []
  );

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, { 
      threshold: 0.1,
      rootMargin: '50px' // Preload slightly before entering viewport
    });

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
      observer.disconnect();
    };
  }, [observerCallback]);

  return (
    <div ref={sectionRef}>
      {isVisible ? children : <SectionLoader />}
    </div>
  );
});

LazySection.displayName = 'LazySection';

// Memoized Section Wrapper component
const SectionWrapper = memo(({ component: Component }) => (
  <LazySection>
    <ErrorBoundary>
      <Suspense fallback={<SectionLoader />}>
        <Component />
      </Suspense>
    </ErrorBoundary>
  </LazySection>
));

SectionWrapper.displayName = 'SectionWrapper';

// Main App component
const App = () => {
  // Optimized prefetching
  useEffect(() => {
    const prefetchPriority = ['Hero', 'About'];
    
    const prefetchComponents = async () => {
      try {
        await Promise.all(
          prefetchPriority.map(async (section) => {
            try {
              await SECTIONS[section];
            } catch (error) {
              console.error(`Error prefetching ${section}:`, error);
            }
          })
        );
      } catch (error) {
        console.error('Error in prefetching components:', error);
      }
    };

    const timeoutId = setTimeout(prefetchComponents, 1000); // Delay prefetch to prioritize initial render
    
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <ErrorBoundary>
      <ThemeProvider>
        <LanguageProvider>
          <AnimatePresence mode="wait">
            <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors duration-300">
              <Navigation />
              
              {/* Hero section without LazySection for immediate loading */}
              <ErrorBoundary>
                <Suspense fallback={<SectionLoader />}>
                  {React.createElement(SECTIONS.Hero)}
                </Suspense>
              </ErrorBoundary>

              {/* Other sections with lazy loading */}
              {Object.entries(SECTIONS)
                .filter(([key]) => key !== 'Hero')
                .map(([key, Component]) => (
                  <SectionWrapper key={key} component={Component} />
                ))}

              <Footer />
            </div>
          </AnimatePresence>
        </LanguageProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default memo(App);