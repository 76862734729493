import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
   // Use lazy initialization to avoid recomputation on every render
   const [isDark, setIsDark] = useState(() => {
      const savedTheme = localStorage.getItem('theme');
      if (savedTheme) return savedTheme === 'dark';
      return window.matchMedia('(prefers-color-scheme: dark)').matches;
   });

   // Memoize the toggle function to avoid unnecessary re-renders
   const toggleTheme = useCallback(() => {
      setIsDark((prev) => !prev);
   }, []);

   useEffect(() => {
      // Use requestAnimationFrame to avoid layout thrashing and improve perceived performance
      requestAnimationFrame(() => {
         const root = document.documentElement;
         if (isDark) {
            root.classList.add('dark');
            localStorage.setItem('theme', 'dark');
         } else {
            root.classList.remove('dark');
            localStorage.setItem('theme', 'light');
         }
      });
   }, [isDark]);

   // Memoize context value to avoid re-renders of context consumers
   const contextValue = useMemo(() => ({ isDark, toggleTheme }), [isDark, toggleTheme]);

   return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => {
   const context = useContext(ThemeContext);
   if (!context) {
      throw new Error('useTheme must be used within a ThemeProvider');
   }
   return context;
};
